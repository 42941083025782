import "../css/admin-page.css";
import {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../components/Loader";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../AuthProvider";
export default function AdminPage(){
    const { user } = useAuth();
    const [bhgames, setBhgames] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const getBhGames = async()=>{
       try{
           await axios.get('https://bht.bet/api/hf8EXkX0UbkaB4kgF8FzciDVdNTHMLO1/hunts')
               .then((res) =>{
                   setBhgames(res.data);
               });
       }
       catch(error){
           console.log(error);
       }
        setLoading(false);
    }
    useEffect(() =>{
        getBhGames();
    },[])

    return user.role === 2 ?  <div className="container admin-page">
        {loading && <Loader/>}

        {!loading &&
            <div className="panel-container">
                <div className="panel-container-header">
                    GTB
                </div>
                <table cellSpacing="0" cellPadding="0">
                    <tr>
                        <th>id</th>
                        <th>name</th>
                        <th>bonuses</th>
                        <th>ending balance</th>
                    </tr>
                    {
                        bhgames.map((item, index)=>{
                            return(
                                <tr key={index} onClick={()=>{
                                    navigate(`${item.id}`)
                                }}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.bonus_count_opened} / {item.bonus_count_total}</td>
                                    <td>{item.info_amount_won}</td>
                                </tr>
                            )
                        })
                    }
                </table>
            </div>
        }
    </div>
        : navigate("/");
}