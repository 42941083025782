import React from "react";
import "../css/wagers-leaderboard-page.css";
import firstPlaceChip from "../assets/img/1st-place.png";
import secondPlaceChip from "../assets/img/2nd-place.png";
import thirdPlaceChip from "../assets/img/3rd-place.png";
import {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../components/Loader";
import Button from "../components/Button";
import {NavLink} from "react-router-dom";
import moment from 'moment';
import CountDownTimer from "../components/CountDownTimer";


export default function WagersLeaderboard(){
    const wagers_api = process.env.REACT_APP_BACKEND_WAGERS;
    const [wagersData, setWagersData] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentTime, setCurrentTime] = useState();
    const [targetTime,setTargetTime] = useState();

    useEffect(() => {
        const fetchData = async() =>{
            try{
                await axios.get(wagers_api)
                    .then((response) => {
                        let data = response.data[1].wagers_list;
                        setWagersData(data);
                        setCurrentTime(moment().unix());
                        setTargetTime(response.data[0].date_info.last_month_epoch);
                    });
            } catch(error){
                console.log(error);
            }
            setLoading(false);
        }
    fetchData();
    }, []);


    return(
        <div className="root page wagers-leaderboard">
            <div className="container">
                {loading && <Loader/>}
                {!loading && <div className="top-3 box-styling">
                    <NavLink to={"/last_month_wagers_leaderboard"}>
                        <Button>Last weekly winners</Button>
                    </NavLink>

                    <h3 className="heading">
                        <CountDownTimer initialTime={targetTime - currentTime}/>
                    </h3>
                    <h3 className="heading">Buhlockay's Weekly Wager Leaderboard</h3>
                    <p className="heading-description">Sign up using code <span className="text-highlight">"mullet"</span> to participate</p>

                    <div className="podium-winners">
                        <div className="second-place podium-wrapper">
                            <p className="username">{wagersData[1]?.name}</p>
                            <p className="rank text-sm">2nd place</p>
                            <p className="wagered text-sm">Wagered <span className="amount-wagered">${wagersData[1].wager}</span></p>
                            <div className="podium">
                                <img className="second-place-chip" src={secondPlaceChip} alt="second place chip"/>
                            </div>
                            <p className="prize">${wagersData[1]?.wager_prize}</p>
                        </div>

                        <div className="first-place podium-wrapper">
                            <p className="username">{wagersData[0]?.name}</p>
                            <p className="rank text-sm">1st place</p>
                            <p className="wagered text-sm">Wagered <span className="amount-wagered">${wagersData[0].wager}</span></p>
                            <div className="podium">
                                <img className="first-place-chip" src={firstPlaceChip} alt="second place chip"/>
                            </div>
                            <p className="prize">${wagersData[0]?.wager_prize}</p>
                        </div>
                        <div className="third-place podium-wrapper">
                            <p className="username">{wagersData[2]?.name}</p>
                            <p className="rank text-sm">3rd place</p>
                            <p className="wagered text-sm">Wagered <span className="amount-wagered">${wagersData[2].wager}</span></p>
                            <div className="podium">
                                <img className="third-place-chip" src={thirdPlaceChip} alt="second place chip"/>
                            </div>
                            <p className="prize">${wagersData[2]?.wager_prize}</p>
                        </div>
                    </div>
                </div>
                }

                {!loading &&
                <div className="wagers-list box-styling">
                    <table cellSpacing="0" cellPadding="0">
                        <tr>
                            <th>#</th>
                            <th>username</th>
                            <th>wagered</th>
                            <th>prize won</th>
                        </tr>
                        {
                            wagersData.map((obj, index)=>{
                                if(index>=3){
                                    return(
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{obj?.name}</td>
                                            <td>${obj?.wager}</td>
                                            <td>${obj?.wager_prize}</td>
                                        </tr>
                                    )
                                }
                            })
                        }

                    </table>
                </div>
                }
            </div>
        </div>
    )
}