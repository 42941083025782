import '../css/header.css';
import logo from "../assets/img/logo.png";
import {Link, NavLink} from "react-router-dom";
import Button from "./Button";
import IconButton from "./IconButton";
import { ReactComponent as Discord } from '../assets/icons/discord.svg';
import { ReactComponent as Kick} from "../assets/icons/kick-logo.svg";
import {useEffect, useState} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {useAuth} from "../AuthProvider";

export default function Header(){
    const {user} = useAuth();
    const auth = useAuth();
    const kick = process.env.REACT_APP_KICK_API;
    const discord_url ="https://api.buhlockay-gg.xyz/api/auth/discord/login";
    // const discord_url = "http://localhost:3001/api/auth/discord/login";
    const [profileMenuOpened, setProfileMenuOpened] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(kick,{
            headers: {
                'Content-type': 'application/json'
            }
        })
            .then(response =>{
                console.log(response);
            })
            .catch(error =>{
                console.log(error);
            })
    }, []);

    return(
        <header>
            <div className="container">
                <div className="logo-wrapper">
                    <NavLink to={"/"}>
                        <img className="logo" src={logo} alt={"buhlockay logo"}/>
                    </NavLink>
                </div>
                <nav className="navigation-menu">
                <ul>
                    <li>
                        <NavLink
                            to={"/"}
                            className={({ isActive, isPending }) =>
                                 isActive ? "active" : ""
                             }
                        >
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"wagers_leaderboard"}>
                            Leaderboard
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"rewards"}>
                            Rewards
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"bonus_hunt"}>
                            Bonus hunt
                        </NavLink>
                    </li>
                </ul>
            </nav>
                <div className={"action-content"}>
                    <a href="https://kick.com/buhlockay" className="kick-status" target="_blank">
                        <div className="animated-kick-logo">
                            <span className="circle-status"></span>
                            <Kick className="kick-logo"/>
                        </div>
                        <span className="text-status">LIVE</span>

                    </a>
                    {user.username ?
                        <div className="profile-btn">
                            <div style={{display:"flex", gridColumnGap:"8px"}}>
                                <img
                                    style={{borderRadius:"6px"}}
                                    src={user.avatar} width="36" height="36"/>
                                <p>{user.username}</p>
                            </div>
                            <ul className="profile-menu-dropdown">
                                <NavLink to={"me"}>
                                    <li>
                                        My profile
                                    </li>
                                </NavLink>
                                {
                                    user.role === 2 ? <NavLink to={"admin"}>
                                            <li>
                                                Admin
                                            </li>
                                        </NavLink>
                                        : null
                                }

                                <li onClick={() => auth.logOut()}>
                                    Log out
                                </li>
                            </ul>
                        </div>
                        :
                        <a href={discord_url} target="_blank" rel='noreferrer'>
                            <Button>
                                <p className="text">Log in w/</p>
                                <Discord/>
                            </Button>
                        </a>
                    }
                </div>
            </div>
        </header>
    );
}